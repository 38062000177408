<template>
  <section>
    <ul class="nav nav-tabs nav-fill">
      <li class="nav-item" v-for="tab in tabs" :key="tab.id">
        <router-link
          class="btn nav-link"
          active-class="active"
          :to="{ name: tab.id }"
          >{{ tab.text }}</router-link
        >
      </li>
    </ul>
    <div class="container">
      <div class="row shadow-sm bg-light py-2">
        <div class="col-sm-12">
          <div class="w-100 d-flex justify-content-end align-items-center">
            <router-link
              target="_blank"
              :to="{ name: 'users.list' }"
              class="btn btn-sm btn-info nr-shadow"
              data-toggle="tooltip"
              data-placement="left"
              title="الذهاب لقائمة المستخدمين"
            >
              قائمة المستخدمين
            </router-link>
          </div>
        </div>
      </div>
      <div class="row shadow-sm bg-light py-2">
        <div class="col-sm-12">
          <RouterView />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import arrays from "@/common/arrays";

export default {
  components: {},
  computed: {
    userId() {
      const route = this.$route;
      return !!route ? route.params.user : "";
    },
    canActivateUser() {
      return this.$store.getters.canActivateUser;
    },
    tabs() {
      let links = [
        { id: "users.info", text: "المعلومات" },
        { id: "users.images", text: "الصور" },
        { id: "users.mobiles", text: "الهواتف" },
        { id: "users.auctions", text: "المزادات" },
        { id: "users.blocks", text: "حظر الشركات" },
        { id: "users.logs", text: "النشاطات" },
      ];

      const userAccount = { id: "users.status", text: "حالة الحساب" };

      if (this.canActivateUser) {
        links = arrays.pushAtPosition(links, userAccount, 3);
      }

      const list = [];
      for (const link of links) {
        list.push({ ...link, params: { user: this.userId } });
      }

      return list;
    },
  },
};
</script>

<style scoped>
.nav-link {
  width: 100%;
  border-radius: 0;
  background-color: #f06b78;
  color: white;
}

.active {
  background-color: white;
  color: #f06b78;
}
</style>
